//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ordini_mixin from "../mixin/ordini_mixin";
import OrdineElement from "./ordine_element_desktop";
export default {
  components: {
    OrdineElement,
  },
  computed: {},
  mixins: [ordini_mixin],
};
