//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrdineDettaglio from "./ordine_dettaglio_desktop";
import EliminaOrdine from "../elimina_ordine_dialog";
import ordine_element_mixin from "../mixin/ordine_element_mixin";
// MessaggioForm from "../../messaggi/desktop/nuova_conversazione_desktop.vue"
export default {
  components: {
    OrdineDettaglio,
    //MessaggioForm,
    EliminaOrdine,
  },
  mixins: [ordine_element_mixin],
};
