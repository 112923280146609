//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ElementDect from "./ordine_dettaglio_element_desktop";
import printer from "@/service/printer";
export default {
  components: {
    ElementDect,
  },
  props: {
    //show: Boolean,
    ordine: Object,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    order_tot() {
      return (
        parseFloat(this.ordine.tot) + parseFloat(this.ordine.tot_trasp)
      ).toFixed(2);
    },
  },
  methods: {
    pdf() {
      printer.print_pdf(this.ordine._id.$oid);
    },
  },
};
